import React, { useCallback, useEffect, useRef } from 'react';
import HoverPanel from './HoverPanel';
import { styled, theme } from '@a2d24-ui/theme';
import { Tooltip } from '@a2d24-ui/tooltip';

const MenuOption = ({ route, active, open, onMenuClick, index, siteStatus }) => {
    const ref = useRef();
    const emergencyStatus = siteStatus?.emergency?.active;

    const onClick = useCallback(() => {
        onMenuClick({ path: route.path, index });
    }, [index, onMenuClick, route.path]);

    useEffect(() => {
        const onKeyPress = (e) => {
            if (e.keyCode === 13) {
                if (ref && ref?.current?.contains(e.target)) {
                    e.stopPropagation();
                    onClick();
                }
            }
        };
        window.addEventListener('keydown', onKeyPress);
        return () => {
            window.removeEventListener('keydown', onKeyPress);
        };
    }, [onClick]);

    const renderIcon = (route) => {
        const IconToRender = route.icon;
        return (
            <IconDiv open={open}>
                {IconToRender ? (
                    <IconToRender
                        color={active ? theme.colors.primary : theme.colors.primaryContrastText}
                    />
                ) : (
                    <div>{route.menuName.charAt(0)}</div>
                )}
            </IconDiv>
        );
    };

    const renderMenuItem = ({ open, route }) => {
        let ComponentToRender = OpenMenuItem;
        if (!open) ComponentToRender = React.Fragment;
        return (
            <ComponentToRender>
                {open ? (
                    renderIcon(route)
                ) : (
                    <Tooltip
                        trigger={renderIcon(route)}
                        text={route.menuName}
                        delayDuration={1}
                        arrowProps={{ offset: '10' }}
                    />
                )}
                <MenuText open={open}>{route.menuName}</MenuText>
            </ComponentToRender>
        );
    };

    return (
        <MenuItem tabIndex={0} ref={ref} active={active} visible={open} onClick={onClick}>
            {renderMenuItem({ route, open })}
            <ActiveIndicator active={active} emergencyStatus={emergencyStatus} />
            <HoverPanel
                ignoreRef={ref}
                route={route}
                open={open}
                active={active}
                siteStatus={siteStatus}
                onClose={() => onMenuClick({ index: null })}
            />
        </MenuItem>
    );
};

export default MenuOption;

const MenuItem = styled('div', {
    display: 'grid',
    width: '203px',
    marginLeft: '21px',
    gridTemplateColumns: 'auto 18px',
    fontSize: '$6',
    textTransform: 'uppercase',
    color: '$primaryContrastText',
    textDecoration: 'none',
    transition: 'color 0.3s linear, margin 0.3s, width 0.3s',
    minHeight: '118px',
    position: 'relative',
    overflow: 'initial',
    borderTopLeftRadius: '$6',
    borderBottomLeftRadius: '$6',
    cursor: 'pointer',
    variants: {
        active: {
            true: {
                color: '$primary',
                backgroundColor: '$backgroundCard',
                borderRight: '1px solid $primary',
            },
        },
        visible: {
            false: {
                marginLeft: '0px',
                width: '70px',
                gridTemplateColumns: '60px 18px',
            },
        },
    },
});

const OpenMenuItem = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

const MenuText = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    transition: 'opacity 0.3s',
    opacity: '0',
    padding: '0 15px 15px',
    variants: {
        open: { true: { opacity: '1' } },
    },
});

const IconDiv = styled('div', {
    margin: 'auto',
    width: '32px',
    height: '32px',
    position: 'relative',
    fontSize: 'x-large !important',
    textAlign: 'center',
    transition: 'margin 0.3s',
    zIndex: 1,
    variants: {
        open: {
            true: {
                margin: 'inherit',
            },
        },
    },
});

const ActiveIndicator = styled('div', {
    opacity: '0',
    position: 'absolute',
    width: '18px',
    top: '-22.5px',
    right: 0,
    backgroundColor: '#fff',
    margin: '5px 0',
    height: '158px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '20px',
    variants: {
        active: {
            true: {
                opacity: '1',
                '&::before': {
                    content: '',
                    position: 'absolute',
                    backgroundColor: '$primary',
                    top: '-13px',
                    right: 0,
                    square: '30px',
                    borderRadius: '20px',
                },
                '&::after': {
                    content: '',
                    position: 'absolute',
                    backgroundColor: '$primary',
                    bottom: '-7px',
                    right: 0,
                    square: '30px',
                    borderTopRightRadius: '20px',
                },
            },
        },
        emergencyStatus: {
            true: {
                '&::before': {
                    square: '31px',
                },
                '&::after': {
                    bottom: '-8px',
                },
            },
        },
    },
});
