import React from 'react';
import { keyframes, styled } from '@a2d24-ui/theme';
import {
    DotFilledIcon,
    LockClosedIcon,
    TriangleDownIcon,
    MixerHorizontalIcon,
} from '@radix-ui/react-icons';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { useNavigate } from 'react-router-dom';
import { useStores } from '@a2d24/emr-state/stores';
import { observer } from '@a2d24/emr-state/mobx-react-lite';
import { useQueryClient } from 'react-query';

const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(DropdownMenuPrimitive.Content, {
    minWidth: 220,
    backgroundColor: 'white',
    borderRadius: 6,
    padding: 5,
    boxShadow:
        '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
    '@media (prefers-reduced-motion: no-preference)': {
        animationDuration: '400ms',
        animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
        willChange: 'transform, opacity',
        '&[data-state="open"]': {
            '&[data-side="top"]': { animationName: slideDownAndFade },
            '&[data-side="right"]': { animationName: slideLeftAndFade },
            '&[data-side="bottom"]': { animationName: slideUpAndFade },
            '&[data-side="left"]': { animationName: slideRightAndFade },
        },
    },
});

const itemStyles = {
    all: 'unset',
    fontSize: '$5',
    lineHeight: 1,
    color: '$darkText',
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    height: 50,
    padding: '0 5px',
    position: 'relative',
    paddingLeft: 25,
    userSelect: 'none',

    '&[data-disabled]': {
        color: '$disabled',
        pointerEvents: 'none',
    },

    '&:focus': {
        backgroundColor: '$primary',
        color: 'white',
    },
};

const StyledItem = styled(DropdownMenuPrimitive.Item, { ...itemStyles });
const StyledCheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem, { ...itemStyles });
const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, { ...itemStyles });
const StyledTriggerItem = styled(DropdownMenuPrimitive.TriggerItem, {
    '&[data-state="open"]': {
        backgroundColor: '$primary',
        color: 'white',
    },
    ...itemStyles,
});

const StyledLabel = styled(DropdownMenuPrimitive.Label, {
    paddingLeft: 25,
    fontSize: 12,
    lineHeight: '25px',
    color: '$primary',
});

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
    height: 1,
    backgroundColor: '$primary4',
    margin: 5,
});

const StyledItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, {
    position: 'absolute',
    left: 0,
    width: 25,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const StyledArrow = styled(DropdownMenuPrimitive.Arrow, {
    fill: 'white',
});

// Exports
const DropdownMenu = DropdownMenuPrimitive.Root;
const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
const DropdownMenuContent = StyledContent;
const DropdownMenuItem = StyledItem;
const DropdownMenuCheckboxItem = StyledCheckboxItem;
const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
const DropdownMenuRadioItem = StyledRadioItem;
const DropdownMenuItemIndicator = StyledItemIndicator;
const DropdownMenuTriggerItem = StyledTriggerItem;
const DropdownMenuLabel = StyledLabel;
const DropdownMenuSeparator = StyledSeparator;
const DropdownMenuArrow = StyledArrow;

const RightSlot = styled('div', {
    marginLeft: 'auto',
    paddingLeft: 20,
    color: '$primary',
    ':focus > &': { color: 'white' },
    '[data-disabled] &': { color: '$disabled' },
});

const UserProfile = styled('button', {
    // Reset
    appearance: 'none',
    borderWidth: '0',
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    fontSize: '1em',
    lineHeight: '1',
    outline: 'none',
    textDecoration: 'none',
    userSelect: 'none',
    textAlign: 'left',
    WebkitTapHighlightColor: 'transparent',
    '&::before': {
        boxSizing: 'border-box',
    },
    '&::after': {
        boxSizing: 'border-box',
    },
    '&:hover': {
        backgroundColor: '$primary2',
    },
    '&:focus': {
        backgroundColor: '$primary2',
    },
    '&[data-state="open"]': {
        backgroundColor: '$primary2',
    },
    backgroundColor: '$primary',

    display: 'grid',
    width: '100%',
    height: '70px',
    alignItems: 'center',
    gridTemplateColumns: 'auto minmax(0, 1fr) auto',
    padding: '0 25px',
    cursor: 'pointer',
});

const Avatar = styled('div', {
    width: 45,
    height: 45,
    borderRadius: '100vw',
    background: '$primary2',
    border: '1px solid white',
    color: 'white',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '$7',
});

const UserNameContainer = styled('div', {
    color: 'white',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '$3',

    'p:first-child': {
        fontWeight: 300,
        width: '100%',
        fontSize: '$6',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    'p:last-child': {
        fontSize: '$4',
        fontWeight: 500,
        textTransform: 'capitalize',
    },
});



export const UserProfileMenu = ({ logout }) => {
    const navigate = useNavigate();
    const { authStore } = useStores();
    const { displayName } = authStore;
    const queryClient = useQueryClient();


    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <UserProfile tabIndex={0}>
                    <Avatar>
                        {displayName
                            .match(/(\b\S)?/g)
                            .join('')
                            .match(/(^\S|\S$)?/g)
                            .join('')
                            .toUpperCase()}
                    </Avatar>
                    <UserNameContainer>
                        <p>{displayName}</p>
                        <p>Diagnostics</p>
                    </UserNameContainer>
                    <div aria-label="User Menu">
                        <TriangleDownIcon color="white" width={30} height={30} />
                    </div>
                </UserProfile>
            </DropdownMenuTrigger>

            <DropdownMenuContent sideOffset={0}>

                <DropdownMenuItem onClick={() => navigate('/emr/preferences')}>
                    Preferences
                    <RightSlot>
                        <MixerHorizontalIcon />
                    </RightSlot>
                </DropdownMenuItem>

                <DropdownMenuSeparator />

                <DropdownMenuItem onClick={logout}>
                    Logout
                    <RightSlot>
                        <LockClosedIcon />
                    </RightSlot>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

export default observer(UserProfileMenu);
