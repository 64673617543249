import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, Text } from '@a2d24-ui/theme';
import { TextInput } from '@a2d24-ui/input';
import { Field } from 'react-final-form';
import { Button } from '@a2d24-ui/button';
import { ColumnFlex } from '@a2d24-ui/theme/stitches';

import { requiredArrayValidator, requiredValidator } from '../../emr-core/utils/requiredValidator';
import { validMobileNumberValidator } from '@a2d24/emr-core/utils/phoneNumberValidators';

import { PhoneNumberInput } from '@a2d24-ui/phone-number-input';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.css';

registerPlugin(FilePondPluginFileEncode);
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

export const requiredFileValidator = (errorMessage) => (value) => {
    const hasFile = value && value.length > 0;
    if (!hasFile) return errorMessage

    const fileHasContents = value[0].getFileEncodeBase64String();
    if (!fileHasContents) return errorMessage

    return undefined
};

const RFFPhoneInput = ({ input, ...rest }) => {
    const { value, onChange, ...inputRest } = input;

    return <PhoneNumberInput value={value} onChange={onChange} {...rest} {...inputRest} />;
};

const RFFFilePond = ({ input, meta, ...rest }) => {
    const { value, onChange, ...inputRest } = input;

    return (
        <>
            <FilePond
                files={value}
                onupdatefiles={onChange}
                {...rest}

                // onaddfile={() => {
                //     setFileAdded(true);
                // }}
                // onremovefile={() => {
                //     setFileAdded(false);
                //     onUploadSuccess && onUploadSuccess(undefined);
                // }}
            />
            {
                meta.touched && meta.error && <p>{meta.error}</p>
            }
        </>
    );
};

const PatientCaptureForm = ({ handleSubmit, dirty, invalid, submitting, form, values }) => {
    const navigate = useNavigate();
    return (
        <StyledForm onSubmit={handleSubmit}>
            <Text.Subtitle>Send Diagnostic Results</Text.Subtitle>

            <Text.SectionHeading>Doctor Details</Text.SectionHeading>

            <Field
                component={TextInput}
                name={'hpcsa_number'}
                id={'hpcsa_number'}
                placeholder={'Full HPCSA Number'}
                validate={requiredValidator(`Doctor's HPCSA Number is required`)}
                css={{marginBottom: '0.5em'}}
            />
            {/*<Text.SummarySectionHeading>This field is used to password protect the results. Please ensure it is captured*/}
            {/*    correctly</Text.SummarySectionHeading>*/}

            <Field
                component={RFFPhoneInput}
                name={'contact_number'}
                id={'contact_number'}
                placeholder={`Doctor's Mobile Number`}
                defaultCountry={'ZA'}
                validate={validMobileNumberValidator('Invalid mobile number')}
            />


            <Text.SectionHeading>Patient Details</Text.SectionHeading>

            <Field
                component={TextInput}
                name={'patient_name'}
                id={'patient_name'}
                placeholder={'Patient Name'}
            />
            {/*<DragAndDrop css={{ flex: 1 }}>*/}
            <Field
                component={RFFFilePond}
                name='file'
                id={'file'}
                maxFileSize={'5MB'}
                allowMultiple={false}
                maxFiles={1}
                credits={false}
                acceptedFileTypes={['application/pdf']}
                labelIdle='Drag & Drop the PDFs or <span class="filepond--label-action">Browse</span>'
                validate={requiredFileValidator('A file is required')}
            />

            {/*</DragAndDrop>*/}
            <ActionContainer>
                <Button
                    css={{ width: '120px' }}
                    variant={'outlined'}
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        form.restart();
                    }}
                >
                    CLEAR
                </Button>
                <Button
                    css={{ width: '120px' }}
                    color={'secondary'}
                    onClick={handleSubmit}
                    disabled={submitting || invalid}
                    aria-label={'CREATE'}
                >
                    SEND
                </Button>
            </ActionContainer>
        </StyledForm>
    );
};

export default PatientCaptureForm;

const StyledForm = styled('form', {
    width: '100%',
    height: '100%',
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'auto'
});

const ActionContainer = styled('div', {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',

    '&>button': {
        margin: '10px'
    }
});


const DragAndDrop = styled(ColumnFlex, {
    border: '2px dashed $lightText',
    width: '100%',
    minHeight: '220px',
    borderRadius: '$3',
    backgroundColor: '$containerBackground',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    position: 'relative'
});
