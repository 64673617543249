export default class EmrReception {
    provides() {
        return {
            EmrRoutes: [() => require('./routes').default],
            SidebarItems: [
                () => ({
                    menuName: 'Send Results',
                    icon: require('@a2d24-icons/launcher-icon').LauncherIcon,
                    path: '/diagnostics/send',
                })
            ]
        };
    }

}
