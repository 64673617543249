import React from 'react';
import { styled } from '@a2d24-ui/theme';
import TextField from '@a2d24-ui/textfield';
import Button from '../../components/Button';
import { useStores } from '@a2d24/emr-state/stores';
import { observer } from '@a2d24/emr-state/mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import SuccessSVG from '../../img/success.svg';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags'; // FIXME: THis increases bundle size by 44kb - can we self host and not inline?
import 'react-phone-number-input/style.css';
import { inputStyles } from '@a2d24-ui/textfield';

const SuccessContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2ch',
    color: '$primaryBlack',
    alignSelf: 'start',
    h1: {
        fontWeight: 600,
        paddingTop: '2em',
    },
    p: {
        color: '$lightText',
        paddingBottom: '2ch',
    },
    img: {
        width: 220,
        paddingTop: '3em',
    },
});
const SuccessView = () => {
    const navigate = useNavigate();
    return (
        <SuccessContainer>
            <img src={SuccessSVG} alt="MFA Registration Successful" />
            <h1>Thanks for taking patient security seriously!</h1>
            <p>
                You can revisit your security and two factor authentication preferences from your
                profile at any time
            </p>
            <Button type="submit" variant="secondary" onClick={() => navigate('/')}>
                Continue
            </Button>
        </SuccessContainer>
    );
};

export default observer(({ setScreen }) => {
    const { mfaStore } = useStores();

    const { requestInFlight, errorMessage, associationSuccessful, challengePending, mfaAuthToken } =
        mfaStore;
    const [number, setNumber] = React.useState('');
    const [code, setCode] = React.useState('');

    React.useEffect(() => {
        mfaStore.setAssociationInProgress(true);
        return () => mfaStore.setAssociationInProgress(false);
    }, []);

    React.useEffect(() => {
        if (associationSuccessful && mfaAuthToken) {
            // This only happens during the management workflow, not initial setup
            setScreen('home');
        }
    }, [associationSuccessful, mfaAuthToken]);

    const handleSubmitNumber = (e) => {
        e.preventDefault();
        mfaStore.associateSMS(number);
    };

    const handleSubmitOTP = (e) => {
        e.preventDefault();
        mfaStore.verifyMFA(code);
    };

    const renderAssociationForm = () => (
        <>
            <h2>Where do you want to receive your codes?</h2>
            <p>You'll get a one-time code to make sure it's working</p>
            <form onSubmit={handleSubmitNumber}>
                <PhoneInput
                    placeholder="Phone Number"
                    value={number}
                    onChange={setNumber}
                    flags={flags}
                    defaultCountry="ZA"
                    autoFocus
                    autoComplete={'off'}
                />
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <div>
                    <Button type="button" variant="outlined" onClick={() => setScreen('home')}>
                        Back
                    </Button>
                    <Button
                        type="submit"
                        variant="secondary"
                        disabled={requestInFlight}
                        onClick={handleSubmitNumber}
                    >
                        Next
                    </Button>
                </div>
            </form>
        </>
    );

    const renderOtpForm = () => (
        <>
            <h2>Verify your SMS number</h2>
            <p>Enter the code sent to your phone number to make sure everything works</p>
            <form onSubmit={handleSubmitOTP}>
                <TextField
                    id="otp"
                    label="6-digit Code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    variant="large"
                    inputCss={{
                        '&:focus': {
                            background: '$primary4',
                        },
                    }}
                    labelCss={{
                        fontSize: '1em',
                        fontWeight: '300',
                    }}
                    autoFocus
                />
                {errorMessage && <p className="error-message">{errorMessage}</p>}

                <div>
                    <Button
                        type="button"
                        variant="outlined"
                        onClick={() => {
                            mfaStore.abandonChallenge(); // Cancel the current challenge
                            mfaStore.setErrorMessage(null);
                            setScreen('home');
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="secondary"
                        disabled={requestInFlight}
                        onClick={handleSubmitOTP}
                    >
                        Verify
                    </Button>
                </div>
            </form>
        </>
    );

    if (associationSuccessful) return <SuccessView />;
    return <Content>{challengePending ? renderOtpForm() : renderAssociationForm()}</Content>;
});

const Content = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '2ch',
    color: '$primaryBlack',
    alignItems: 'center',
    padding: '4ch',
    maxWidth: '80ch',
    width: '90vw',
    alignSelf: 'flex-start',

    h2: {
        fontWeight: 300,
        fontSize: '1.5em',
    },
    '> p': {
        fontWeight: 200,
    },
    form: {
        width: '75%',
        marginTop: 25,
        '> div': {
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '1ch',
        },
    },
    '.PhoneInput': {
        input: {
            ...inputStyles,
        },
        marginBottom: '4ch',
    },
    button: {
        minWidth: '15ch',
        fontSize: '1em',
        fontWeight: 400,
        justifySelf: 'flex-end',
        display: 'block',
        textAlign: 'center',
    },

    '.error-message': {
        border: '1px solid $warning',
        borderRadius: '$2',
        padding: '2ch',
        color: '$warning',
        textAlign: 'center',
        marginBottom: '1ch',
    },
});
