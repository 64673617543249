import { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';

export const possibleNumberValidator = (errorMessage) => (value) =>
    value && isPossiblePhoneNumber(value) ? undefined : errorMessage;
export const validNumberValidator = (errorMessage) => (value) =>
    value && isValidPhoneNumber(value) ? undefined : errorMessage;

const minStringLength = (str, minCharacters) => {
    if (str) return str?.length >= minCharacters;
    else return false;
};

export const validMobileNumberValidator = (errorMessage) => (value) =>
    value && isValidPhoneNumber(value) && minStringLength(value, 12) ? undefined : errorMessage;
