import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

import { ColumnFlex, darkTheme, styled } from '@a2d24-ui/theme';
import { useStores } from '@a2d24/emr-state/stores';
import { observer } from '@a2d24/emr-state/mobx-react-lite';
import { Route, Routes } from 'react-router-dom';
import LoadingRoute from './LoadingRoute';

const EmrRoutes = engine.providers('EmrRoutes').map((generator) => generator());

const EmrLayout = (props) => {
    const { uiStore, authStore } = useStores();
    const { children, staticContext, ...other } = props;


    return (
        <AppContainer>
            <Container className={localStorage.getItem('theme') === 'dark' ? darkTheme : ''}>
                <HeaderContainer {...other}>
                    <Header
                        open={uiStore.menuOpen}
                        setOpen={() => uiStore.setOpen(!uiStore.menuOpen)}
                    />
                </HeaderContainer>
                <ContentContainer>
                    <Sidebar open={uiStore.menuOpen} {...other}  />
                    <Content>
                        <Routes>
                            {EmrRoutes}
                            <Route path="*" element={<LoadingRoute />} />
                        </Routes>
                    </Content>
                </ContentContainer>
            </Container>
        </AppContainer>
    );
};

export default observer(EmrLayout);

const AppContainer = styled(ColumnFlex, {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
});

const Container = styled('div', {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'grid',
    boxSizing: 'border-box',
    gridTemplateRows: '70px auto',
    gridTemplateAreas: "'header' 'content-container'",
    color: '$primaryText',
});

const HeaderContainer = styled('div', {
    width: '100%',
    height: '100%',
    display: 'flex',
    gridArea: 'header',
    boxSizing: 'border-box',
});

const ContentContainer = styled('div', {
    width: '100%',
    height: '100%',
    gridArea: 'content-container',
    display: 'grid',
    gridTemplateColumns: 'auto minmax(0, 1fr)',
    overflow: 'hidden',
    boxSizing: 'border-box',
    backgroundColor: '$background',
});

const Content = styled('div', {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    flex: '1',
    display: 'flex',
    overflowY: 'auto',
    boxSizing: 'border-box',
});
