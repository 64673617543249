import { makeAutoObservable, reaction, runInAction } from 'mobx';
import axios from 'axios';
import semverLt from 'semver/functions/lt';

const API_URL = process.env.REACT_APP_API_ENDPOINT;
const APP_VERSION = process.env.REACT_APP_VERSION;

export default class AuthStore {
    authenticated = false;
    user = null;
    idToken = null;
    accessToken = null;
    token = null;
    appVersion = APP_VERSION;
    shouldUpdateFrontend = false;

    axiosInterceptors = null;

    authorizing = false;
    authorizingErrorMessage = null;
    authorized = false;

    displayName = null;

    initialUserProfile = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);

        reaction(
            () => this.authorized,
            () => {
                if (this.authorized) {
                    engine.triggerLifecycleEvent('onLogin');
                } else {
                    engine.triggerLifecycleEvent('onLogout');
                }
            }
        );

        reaction(
            () => this.authenticated,
            (authenticated) => {
                if (authenticated) {
                    this.setupAutoLogout();
                    this.updateCredentials();
                }
            }
        );

        reaction(
            () => this.token,
            (newRole) => {
                // Update axios interceptors
                if (this.axiosInterceptors) {
                    axios.interceptors.request.eject(this.axiosInterceptors);
                }
                this.axiosInterceptors = axios.interceptors.request.use(
                    (request) => {
                        if (request.url.startsWith(API_URL)) {
                            request.headers['Authorization'] = `Bearer ${this.token}`;
                            request.headers['Content-Type'] = 'application/json';
                        }

                        return request;
                    },
                    (error) => {
                        return Promise.reject(error);
                    }
                );
            }
        );

        this.logoutTimer = null;
    }


    setAuthenticated(newAuthenticatedValue) {
        this.authenticated = newAuthenticatedValue;

        if (newAuthenticatedValue === false) {
            this.reset();
        }
    }

    updateCredentials() {
        const newAccessToken = this.rootStore.mfaStore.getAccessToken();
        this.accessToken = newAccessToken;

        if (newAccessToken) {
            this.onAccessTokenAvailable();
        }
    }

    logout() {
        this.rootStore.mfaStore.logout();
        this.clearAutoLogout();
    }

    reset() {
        this.authenticated = false;
        this.idToken = null;
        this.accessToken = null;
        this.token = null;
        this.shouldUpdateFrontend = false;

        this.authorizing = false;
        this.authorized = false;
        this.authorizingErrorMessage = null;

        this.initialUserProfile = null;

        // Eject any previous interceptors
        if (this.axiosInterceptors) {
            axios.interceptors.request.eject(this.axiosInterceptors);
            this.axiosInterceptors = null;
        }
    }


    shouldUpdateVersion(frontendLatestVersion = '1.0.0') {
        if (semverLt(this.appVersion, frontendLatestVersion)) this.shouldUpdateFrontend = true;
    }

    async onAccessTokenAvailable() {
        try {
            this.authorizing = true;
            const response = await axios.post(`${API_URL}/Authentication/authorize`, {
                token: this.accessToken,
                version: APP_VERSION
            });

            runInAction(() => {
                const {
                    token,
                    user_name,
                    frontend_min_version
                } = response.data;
                this.token = token;
                this.displayName = user_name || 'Netcare User';
                this.shouldUpdateVersion(frontend_min_version);

                this.authorized = true;
            });
        } catch (e) {
            console.log(e);
            runInAction(() => {
                if (e?.response?.status === 401 || e?.response?.status === 403) {
                    this.authorizingErrorMessage = e.response.data;
                } else {
                    console.error(e?.message.status, e?.message.data);
                    this.authorizingErrorMessage =
                        'Unable to authorize your account. If you do have access to the system, please try logging out logging in again.';
                }
            });
        }

        runInAction(() => {
            this.authorizing = false;
        });
    }

    setupAutoLogout() {
        const access_token = this.rootStore.mfaStore.getAccessToken(true);
        if (!access_token) return;

        const autoLogoutInMs = access_token.exp * 1000 - Date.now();

        this.logoutTimer = setTimeout(() => {
            this.logout();
        }, Math.max(0, autoLogoutInMs));

        console.debug(
            `[Auth] Auto Logout has been set [in ${Math.floor(autoLogoutInMs / 1000)} seconds]`
        );
    }

    clearAutoLogout() {
        if (this.logoutTimer) {
            clearTimeout(this.logoutTimer);
            console.debug(`[Auth] Auto Logout has been cleared`);
        }
        this.logoutTimer = null;
    }
}
