import React from 'react';
import { styled } from '@a2d24-ui/theme';

export const LauncherIcon = styled(
    React.forwardRef(({ color = '#499BAD', ...props }, forwardedRef) => (
        <svg
            width="30"
            height="30"
            {...props}
            ref={forwardedRef}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill={color} fillRule="nonzero">
                <path d="m1.001 16.72 5.17 1.456a.47.47 0 0 1 .283.685l-.688 1.235a.474.474 0 0 0 .098.583l3.316 2.958a.468.468 0 0 0 .579.04l1.278-.87a.47.47 0 0 1 .732.323l.689 4.874a.473.473 0 0 0 .802.268l4.25-4.265a.353.353 0 0 1 .074-.06c.224-.149 1.306-.979.732-2.741l-.024-.063-.728-1.59a.474.474 0 0 1 .248-.63c5.972-2.596 9.131-7.777 10.701-12.12.952-2.636 1.35-5.201 1.483-6.275a.47.47 0 0 0-.48-.528c-2.34.048-11.822.952-18.837 12.067a.472.472 0 0 1-.605.177L7.426 10.94s-.708-.61-2.341.87l-4.28 4.111a.475.475 0 0 0 .196.799zm19.92-9.917a2.01 2.01 0 0 1 2.222-1.228 1.988 1.988 0 0 1 1.48 2.687 2.01 2.01 0 0 1-2.224 1.228 1.99 1.99 0 0 1-1.479-2.687zM.789 26.356c.2 0 .401-.08.555-.233l3.615-3.615a.788.788 0 0 0-1.113-1.113L.23 25.01a.788.788 0 0 0 .559 1.346zM7.19 24.735 3.574 28.35a.788.788 0 0 0 .555 1.346c.2 0 .401-.079.555-.232l3.615-3.616a.788.788 0 0 0 0-1.113.784.784 0 0 0-1.11 0zM.789 29.696c.2 0 .401-.079.555-.232l5.283-5.288a.788.788 0 0 0-1.113-1.113L.234 28.35a.788.788 0 0 0 .555 1.346z" />
            </g>
        </svg>
    )),
    {}
);

export default LauncherIcon;
