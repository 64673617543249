export const requiredValidator = (errorMessage) => (value) => value ? undefined : errorMessage;
export const requiredArrayValidator = (errorMessage) => (value) =>
    value && value.length > 0 ? undefined : errorMessage;
export const requiredEmailValidator = (errorMessage) => (value) =>
    (value || '').match(/[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/) ? undefined : errorMessage;

export const requiredIgnoreValidator = (errorMessage, formName, formFields) => (value, form) => {
    const ignoreConditionsMet = formFields.some((field) => {
        return (
            Object.keys(form?.[formName]?.[0] || {}).includes(field.name) &&
            form?.[formName]?.[0]?.[field.name]?.trim() === field.value
        );
    });
    return ignoreConditionsMet ? undefined : value ? undefined : errorMessage;
};

export const requiredBoundedValidator =
    (errorMessage, lowerBound = null, upperBound = null) =>
    (value) => {
        console.log(value, lowerBound, upperBound);
        if (lowerBound != null && upperBound != null)
            return value >= lowerBound && value <= upperBound ? undefined : errorMessage;
        if (lowerBound != null && !upperBound != null)
            return value >= lowerBound ? undefined : errorMessage;
        if (!lowerBound != null && upperBound != null)
            return value <= upperBound ? undefined : errorMessage;
        return value ? undefined : errorMessage; // if no bounds set, return required validator
    };
