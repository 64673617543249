import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { observer } from '@a2d24/emr-state/mobx-react-lite';

import Login from './views/Login';
import LoginForMFAToken from './views/LoginForMFAToken';
import LoginUsingMFA from './views/LoginUsingMFA';
import SetupMFAFirstTime from './views/first-time-mfa/SetupMFAFirstTime';
import ManageMFA from './views/first-time-mfa/ManageMFA';

const LoginRouter = observer(() => {

    return (
        <Routes>
            <Route path='/mfa/setup' element={<SetupMFAFirstTime />} />
            <Route path='/mfa/manage' element={<ManageMFA />} />
            <Route path='/mfa' element={<LoginUsingMFA />} />
            <Route path='/verification' element={<LoginForMFAToken />} />
            <Route path='/*' element={<Login />} />
        </Routes>
    );
});

export const LoginRootRoutes = (
    <Route path='login/*' element={<LoginRouter />} key='login-root-routes' />
);
