import React from 'react';
import { styled } from '@a2d24-ui/theme';

import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { observer } from '@a2d24/emr-state/mobx-react-lite';
import { CheckboxV2 } from '@a2d24-ui/checkbox';

import { useStores } from '@a2d24/emr-state/stores';
import TextField from '@a2d24-ui/textfield';
import Button from '../components/Button';
import { AuthGraphic } from './first-time-mfa/AuthGraphic';
import KeysSVG from '../img/keys.svg';
import ValidationMethodPicker from './ValidationMethodPicker';
import useTimeout from '../hooks/useTimeout';

const Logo = engine.providers('Logo', () => () => null)();

const VerifyChallengeContainer = styled('div', {
    width: '100vw',
    minHeight: '100vh',
    color: '$primaryBlack',

    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '1.3fr 1fr',

    gap: '4ch',

    '> img': {
        justifySelf: 'start',
        margin: '2ch 4ch',
        maxWidth: '300px',
        gridRow: 1,
        gridColumn: '1 / span 2',
    },

    '> form': {
        justifySelf: 'end',
        display: 'flex',
        flexDirection: 'column',
        gap: '1ch',
        maxWidth: '60ch',
        h1: {
            margin: '1ch 0',
            fontWeight: 500,
            fontSize: '1.5em',
        },
        'a, .disabled-link': {
            textDecoration: 'none',
            color: '$primary',
            fontWeight: 500,
            alignSelf: 'start',
            padding: '0.2ch 0',
            marginBottom: '1ch',
        },
        '.disabled-link': {
            color: '$lightText1',
        },

        '> button': {
            alignSelf: 'start',
            minWidth: '20ch',
        },
        '.error-message': {
            border: '1px solid $warning',
            borderRadius: '$2',
            padding: '2ch',
            color: '$warning',
            textAlign: 'center',
            marginBottom: '1ch',
        },
    },
    '> div': {
        display: 'flex',
        flexDirection: 'column',
        width: 370,
        alignItems: 'center',
        gap: '1ch',
        p: {
            marginTop: '2ch',
            fontWeight: 300,
        },
    },
});

export const VerifyChallenge = observer(({ authenticator, setSelectedAuthenticator }) => {
    const [code, setCode] = React.useState('');
    const [canRequestNewCode, setCanRequestNewCode] = React.useState(true);
    const [choosingValidator, setChoosingValidator] = React.useState(false);
    const { mfaStore } = useStores();
    const { errorMessage, requestInFlight, hasNoActiveAuthenticators, rememberMFA } = mfaStore;
    const navigate = useNavigate();

    React.useEffect(() => {
        mfaStore.setRememberMFA(true);
        mfaStore.setErrorMessage(null);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        mfaStore.verifyMFA(code);
    };

    if (choosingValidator) {
        return (
            <ValidationMethodPicker
                setSelected={(newAuthenticator) => {
                    setSelectedAuthenticator(newAuthenticator);
                    mfaStore.setErrorMessage(null);
                    setCode('');
                    mfaStore.challengeAuthenticator(newAuthenticator.id);
                    setChoosingValidator(false);
                }}
            />
        );
    }

    return (
        <VerifyChallengeContainer>
            <Logo />
            <form onSubmit={handleSubmit}>
                <h1>
                    {`Enter the code we sent to the ${
                        authenticator.oob_channel === 'sms'
                            ? 'phone number ending in'
                            : 'email address'
                    } ${authenticator.name.replaceAll('X', '*')}`}
                </h1>
                <p>
                    We're asking for this code based on your two-factor authentication preferences
                </p>
                <TextField
                    id="bind_code"
                    label="Enter code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    variant="large"
                    inputCss={{
                        '&:focus': {
                            background: '$primary4',
                        },
                    }}
                    labelCss={{
                        fontSize: '1em',
                        fontWeight: '300',
                    }}
                    fieldsetCss={{
                        maxWidth: '80%',
                    }}
                    autoFocus
                />

                {canRequestNewCode ? (
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setCanRequestNewCode(false);
                            mfaStore.challengeAuthenticator(authenticator.id);
                        }}
                    >
                        Request another code
                    </a>
                ) : (
                    <p className="disabled-link">New code sent</p>
                )}
                {mfaStore.mfaAuthenticators.length > 1 && !hasNoActiveAuthenticators && (
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setChoosingValidator(true);
                        }}
                    >
                        Use another verification method
                    </a>
                )}
                {hasNoActiveAuthenticators && (
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            mfaStore.abandonChallenge(); // Cancel the current challenge
                            navigate('./setup');
                        }}
                    >
                        Use another verification method
                    </a>
                )}
                <CheckboxV2
                    id="remember-device"
                    text="Remember this device for 30 days"
                    checked={rememberMFA}
                    onCheckedChange={(value) => mfaStore.setRememberMFA(value)}
                />
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <Button
                    css={{ marginTop: '1ch' }}
                    type="submit"
                    disabled={requestInFlight}
                    onClick={handleSubmit}
                >
                    Sign in
                </Button>
            </form>
            <div>
                <AuthGraphic image={KeysSVG} />
                <p>We're just making sure its you</p>
            </div>
        </VerifyChallengeContainer>
    );
});

const getPreferredAuthenticator = (mfaAuthenticators) => {
    if (mfaAuthenticators.length === 0) {
        return null;
    }

    const activeAuthenticators = mfaAuthenticators.filter((authenticator) => authenticator.active);
    const activeEmailAuthenticators = activeAuthenticators.filter(
        (authenticator) => authenticator.oob_channel === 'email'
    );

    // Prefer the newest active email based authenticator
    if (activeEmailAuthenticators.length > 0) {
        return activeEmailAuthenticators[activeEmailAuthenticators.length - 1];
    }

    // Next preference is the newest active authenticator
    if (activeAuthenticators.length > 0) {
        return activeAuthenticators[activeAuthenticators.length - 1];
    }

    // Last option is to prefer the latest added authenticator
    return mfaAuthenticators[mfaAuthenticators.length - 1];
};

const LoginUsingMFA = () => {
    const queryString = new URLSearchParams(useLocation().search);

    const { mfaStore } = useStores();
    const navigate = useNavigate();
    const { mfaPending, mfaAuthenticators } = mfaStore;

    const defaultAuthenticator = getPreferredAuthenticator(mfaAuthenticators);
    const [selectedAuthenticator, setSelectedAuthenticator] = React.useState(null);

    const next = queryString.get('next') || '/login';

    const timeoutRef = useTimeout(() => {
        mfaStore.reset(true);
        mfaStore.abandonChallenge();
        mfaStore.clearMfaRequired();
        navigate(next);
    }, 5 * 60 * 1000);

    React.useEffect(() => {
        // Next use the latest inactive
        if (defaultAuthenticator) {
            mfaStore.challengeAuthenticator(defaultAuthenticator.id);
        }
    }, []);

    if (mfaAuthenticators.length === 0) return <Navigate to="./setup" />;

    if (!defaultAuthenticator || !mfaPending) {
        return <Navigate to={next} />;
    }

    return (
        <VerifyChallenge
            authenticator={selectedAuthenticator || defaultAuthenticator}
            setSelectedAuthenticator={setSelectedAuthenticator}
        />
    );
};

export default observer(LoginUsingMFA);
