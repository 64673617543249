import React, { useEffect, useState } from 'react';
import { styled } from '@a2d24-ui/theme';
import useEngineListener from '@a2d24/react-engine-dom/hooks/useEngineListener';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuOption from './SidebarComponents/MenuOption';
import { useStores } from '@a2d24/emr-state/stores';
import { observer } from '@a2d24/emr-state/mobx-react-lite';

const sidebarItems = engine.providers('SidebarItems', []).map((generator) => generator());

const Sidebar = ({ open }) => {
    const [routes, setRoutes] = useState([]);
    const [activeRoute, setActiveRoute] = useState('/');
    const [submenuRoutes, setSubmenuRoutes] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [activeRouteManager, setActiveRouteManager] = useState(null);
    const { authStore } = useStores();
    // const currentRole = authStore.selectedRole.role.value.toLowerCase();
    const currentRole = 'user';

    useEffect(() => {
        setRoutes(engine.getRoutes());
    }, []);

    useEffect(() => {
        setActiveRoute(location.pathname);
    }, [location.pathname]);

    useEngineListener('registerSubmenuRoutes', (payload) => {
        const modifiedRoutes = [...submenuRoutes];
        modifiedRoutes.push(payload);
        setSubmenuRoutes(modifiedRoutes);
    });

    useEngineListener('unregisterSubmenuRoutes', (payload) => {
        const modifiedRoutes = [...submenuRoutes];
        modifiedRoutes.pop();
        setSubmenuRoutes(modifiedRoutes);
    });

    const sortFunction = (a, b) => {
        const p1 = a.priority || 0,
            p2 = b.priority || 0;
        if (p1 < p2) return 1;
        return -1;
    };

    const onMenuClick = ({ path, index }) => {
        if (path) {
            setActiveRouteManager(null);
            navigate(path);
        } else {
            setActiveRouteManager(index);
        }
    };

    const renderMenuItems = (routesToRender) => {
        return [
            ...routesToRender,
            ...sidebarItems.filter((item) => {
                return !item.roles || item.roles.indexOf(currentRole) > -1;
            })
        ]
            .filter((r) => !!r.menuName)
            .sort(sortFunction)
            .map((route, index) => {
                const active =
                    activeRouteManager !== null
                        ? index === activeRouteManager
                        : route.path === activeRoute;

                return (
                    <MenuOption
                        key={index}
                        index={index}
                        route={route}
                        active={active}
                        open={open}
                        onMenuClick={onMenuClick}
                    />
                );
            });
    };

    return (
        <StyledMenu open={open}>
            <br />
            {renderMenuItems(
                submenuRoutes.length > 0
                    ? [...routes, ...submenuRoutes[submenuRoutes.length - 1]]
                    : routes
            )}
        </StyledMenu>
    );
};

export default observer(Sidebar);

const StyledMenu = styled('nav', {
    position: 'relative',
    display: 'flex',
    whiteSpace: 'nowrap',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '$sidebarClosedWidth',
    background: '$primary',
    height: '100%',
    textAlign: 'left',
    transition: 'transform 0.3s ease-in-out, width 0.3s',
    userSelect: 'none',
    zIndex: 1,
    variants: {
        open: { true: { width: '$sidebarOpenWidth' } }
    }
});
