import React from 'react';
import { TextInput } from '@a2d24-ui/input';
import PhoneInput from 'react-phone-number-input';
import defaultFlags from 'react-phone-number-input/flags';

//NOTE: Will probably need better handling of props
export const PhoneNumberInput = ({ value, onChange, flags, css, ...rest }) => {
    return (
        <PhoneInput
            value={value}
            onChange={onChange}
            flags={flags || defaultFlags}
            inputComponent={TextInput}
            css={{ width: '100%', ...css }}
            {...rest}
        />
    );
};
