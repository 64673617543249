import { makeAutoObservable, toJS } from 'mobx';

// https://www.unicode.org/cldr/cldr-aux/charts/30/supplemental/day_periods.html
export const getDayPeriod = () => {
    const hourOfDay = new Date().getHours();

    if (hourOfDay >= 0 && hourOfDay < 6) return 'night';
    if (hourOfDay >= 6 && hourOfDay < 12) return 'morning';
    if (hourOfDay >= 12 && hourOfDay < 18) return 'afternoon';

    return 'evening';
};

export default class UIStore {
    menuOpen = false;
    dayPeriod = getDayPeriod();
    patientFileSearchTags = {};
    practiceStreamingList = [];

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.startDayPeriodUpdate();
    }

    setOpen = (value) => {
        this.menuOpen = value;
    };


    setPatientFileSearchTag(tag, value) {
        this.patientFileSearchTags = { ...this?.patientFileSearchTags, [tag]: value };
    }

    clearPatientFileSearchTags() {
        this.patientFileSearchTags = {};
    }

    startDayPeriodUpdate = () => {
        this._timerId = setInterval(() => {
            this.updateDayPeriod();
        }, 1000 * 60);
    };

    updateDayPeriod = () => {
        this.dayPeriod = getDayPeriod();
    };

    get user() {
        return this.rootStore.authStore.user;
    }


}
