import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { styled } from '@a2d24-ui/theme';

import PatientCapture from '@a2d24/emr-patient/views/PatientCapture';


const PatientApp = () => {
    return (
        <Container>
            <ScrollableContainer>
                <Routes>
                    <Route path='send' element={<PatientCapture />} />
                    <Route path='/' element={<Navigate to={'send'} />} />
                </Routes>
            </ScrollableContainer>
        </Container>
    );
};

const PatientRoute = <Route path='diagnostics/*' element={<PatientApp />} key={'diagnostics'} />;

const Container = styled('div', {
    width: '100%',
    height: '100%',
    minHeight: 'min-content',
    position: 'relative',
    overflow: 'initial'
});

const ScrollableContainer = styled(Container, {
    width: '100%',
    height: '100%',
    padding: '0',
    display: 'flex',
    flexDirection: 'column'
});

export default PatientRoute;
