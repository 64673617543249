import React, { useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import axios from 'axios';
import createDecorator from 'final-form-focus';
import PatientCaptureForm from './PatientCaptureForm';

export const API_URL = process.env.REACT_APP_API_ENDPOINT;


const PatientCapture = () => {
    const [initialValues, setInitialValues] = useState({});
    const focusOnError = useMemo(() => createDecorator(), []);


    const onSubmit = async (formValues, form) => {
        try {
            await axios.post(`${API_URL}/Diagnostics/send_result`, {
                cellphone_number: formValues.contact_number,
                b64_file: formValues.file[0].getFileEncodeBase64String(),
                practitioner_number: formValues.hpcsa_number,
                patient_name: formValues.patient_name
            });
            engine.broadcastMessage('snackbar', {
                type: 'success',
                message: 'Form submitted'
            });
            form.restart();

        } catch (e) {
            engine.broadcastMessage('snackbar', {
                type: 'error',
                message: `Could not send document. ${e}`
            });
        }

    };

    return (
        <>
            <Form
                onSubmit={onSubmit}
                decorators={[focusOnError]}
                initialValues={initialValues}
                component={PatientCaptureForm}
            />
        </>
    );
};

export default PatientCapture;
