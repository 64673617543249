import React, { useEffect, useRef } from 'react';
import { styled } from '@a2d24-ui/theme';
import useOnClickOutside from './onOutsideClick';

const HoverPanel = ({ route, open, active, onClose, ignoreRef, siteStatus }) => {
    const ref = useRef();
    const emergencyStatus = siteStatus?.emergency;

    useOnClickOutside({
        ref,
        ignoreRef,
        handler: () => onClose(),
    });

    useEffect(() => {
        const onKeyPress = (e) => {
            if (active && e.key === 'Escape') {
                onClose();
            }
        };
        window.addEventListener('keydown', onKeyPress);
        return () => {
            window.removeEventListener('keydown', onKeyPress);
        };
    }, [onClose]);

    if (!route.hoverComponent) return null;
    if (!active) return null;

    return (
        <HoverContainer
            ref={ref}
            open={open}
            emergencyOpen={emergencyStatus?.active}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <route.hoverComponent onClose={() => onClose()} />
        </HoverContainer>
    );
};

export default HoverPanel;

const HoverContainer = styled('div', {
    position: 'fixed',
    top: '$headerHeight',
    left: '$sidebarClosedWidth',
    overflow: 'hidden',
    transition: 'width 0.3s, height 0.3s, margin 0.3s',
    maxHeight: 'calc(100vh - $headerHeight)',
    height: 'calc(100vh - $headerHeight)',
    maxWidth: 'calc(100vw - $sidebarClosedWidth)',
    background: '$backgroundCard',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.1)',
    variants: {
        open: {
            true: {
                left: '$sidebarOpenWidth',
                maxWidth: 'calc(100vw - $sidebarOpenWidth)',
            },
        },
        emergencyOpen: {
            true: {
                maxHeight: 'calc(100vh - $headerHeight - $emergencyDivHeight)',
                height: 'calc(100vh - $headerHeight - $emergencyDivHeight)',
            },
        },
    },
});
