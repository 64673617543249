import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { styled } from '@a2d24-ui/theme';

import Preferences from '@a2d24/emr-core/views/Preferences';

const EmrCore = () => {
    return (
        <Container>
            <Routes>
                <Route path="preferences" element={<Preferences />} />
                <Route path="/" element={<Navigate to={'preferences'} />} />
            </Routes>
        </Container>
    );
};

const EmrCoreRoutes = <Route path="emr/*" element={<EmrCore />} key={'emr'} />;

const Container = styled('div', {
    width: '100%',
    height: '100%',
    overflow: 'auto',
});

export default EmrCoreRoutes;
