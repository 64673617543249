import React from 'react';
import { styled } from '@a2d24-ui/theme';

const Burger = ({ open, setOpen }) => {
    return (
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
            <StyledDiv open={open} />
            <StyledDiv open={open} />
            <StyledDiv open={open} />
        </StyledBurger>
    );
};

export default Burger;

const StyledDiv = styled('div', {
    width: '2rem',
    height: '0.2rem',
    background: '#fff',
    borderRadius: '10px',
    transition: 'all 0.3s linear',
    position: 'relative',
    transformOrigin: '1px',
    '&:first-child': {
        transform: 'rotate(0)',
    },
    '&:nth-child(2)': {
        opacity: '1',
        transform: 'translateX(0)',
    },
    '&:nth-child(3)': {
        width: "'1.5rem",
        transform: 'rotate(0)',
    },
    variants: {
        open: {
            true: {
                '&:first-child': {
                    transform: 'rotate(45deg)',
                },
                '&:nth-child(2)': {
                    opacity: '0',
                    transform: 'translateX(20px)',
                },
                '&:nth-child(3)': {
                    width: '2rem',
                    transform: 'rotate(-45deg)',
                },
            },
        },
    },
});

const StyledBurger = styled('button', {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '2rem',
    height: '2rem',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '2px',
    zIndex: '10',
    margin: 'auto',
    boxSizing: 'content-box',
    position: 'relative',
    '&:focus': {
        outline: 'none',
    },
    '& div': {},
});
