import React from 'react';
import { styled } from '@a2d24-ui/theme';
import { useStores } from '@a2d24/emr-state/stores';
import { Input } from '@a2d24-ui/input';
import { Button } from '@a2d24-ui/button';
import { useNavigate } from 'react-router-dom';
import { formatDistance, fromUnixTime } from 'date-fns';

const PreferencesContainer = styled('div', {
    padding: '3ch',
    display: 'flex',
    flexDirection: 'column',
    gap: '1ch',
    '> h1': {
        fontSize: '1.5em',
        fontWeight: 500
    },

    '> div': {
        marginTop: '2ch',
        display: 'flex',
        flexDirection: 'column',
        gap: '1ch',
        alignItems: 'flex-start',
        // border: '1px solid hotpink',
        h2: {
            fontSize: '1em',
            fontWeight: 400
        },
        p: {
            maxWidth: '80ch',
            fontWeight: 300
        }
    }
});

const PasswordReset = () => {
    const { mfaStore } = useStores();

    if (mfaStore.realm === 'Netcare-Users') {
        return (
            <div>
                <h2>Password</h2>
                <p>
                    You are currently using your Netcare/Medicross credentials, please log a Sigma
                    if you require assistance with accessing your account.
                </p>
                <Button
                    variant='outlined'
                    css={{ marginTop: '1ch', fontSize: '1em', fontWeight: 300 }}
                    disabled
                >
                    Reset Password
                </Button>
            </div>
        );
    }
    return (
        <div>
            <h2>Password</h2>
            <p>
                To change your password, click the button below. You will receive an email for
                verification with further instructions
            </p>
            <Button
                type='button'
                variant='outlined'
                css={{ marginTop: '1ch', fontSize: '1em', fontWeight: 300 }}
                onClick={() => {
                    mfaStore.resetPassword();
                    engine.broadcastMessage('snackbar', {
                        type: 'success',
                        message: `Password reset instructions emailed to ${mfaStore.username}`
                    });
                }}
            >
                Reset Password
            </Button>
        </div>
    );
};

const Manage2FA = () => {
    const navigate = useNavigate();
    const { mfaStore } = useStores();
    const [savedMfaToken, setSavedMfaToken] = React.useState(mfaStore.loadMFAToken(true));

    React.useEffect(() => {
        // This token could be stale, so force reauthentication
        mfaStore.setMfaAuthToken(null);
    }, []);

    return (
        <div>
            <h2>Two Factor Authentication</h2>
            <p>
                2FA Makes your account more secure by verifying your identity when you log in from a
                new device. You can change your 2FA preferences by clicking the button below. You
                will be taken to a login screen for additional verification before changes can be
                made.
            </p>
            <Button
                type='button'
                variant='outlined'
                css={{ margin: '1ch 0', fontSize: '1em', fontWeight: 300 }}
                onClick={() => navigate('/login/verification')}
            >
                Change 2FA Preferences
            </Button>
            {savedMfaToken && (
                <>
                    <p>
                        Your device is currently trusted and your 2FA will expire in{' '}
                        <b>{formatDistance(fromUnixTime(savedMfaToken.exp), new Date())}</b>. Click
                        on Forget Device if you would like to be prompted for 2FA again on your next
                        login.
                    </p>
                    <Button
                        type='button'
                        variant='outlined'
                        css={{ margin: '1ch 0', fontSize: '1em', fontWeight: 300 }}
                        onClick={() => {
                            mfaStore.clearMFAToken();
                            setSavedMfaToken(mfaStore.loadMFAToken(true));
                        }}
                    >
                        Forget Device
                    </Button>
                </>
            )}
        </div>
    );
};

const Preferences = () => {
    const { mfaStore, authStore } = useStores();
    return (
        <PreferencesContainer>
            <h1>User Preferences</h1>
            <p>These settings will only be applied to your account</p>
            <div>
                <h2>Username</h2>
                <p>You have authenticated using</p>
                <Input value={mfaStore.username} css={{ width: '45ch' }} disabled />
                {/*<p>*/}
                {/*    This account is associated with the practitioner ID*/}
                {/*    <b>{authStore.user.identifier}</b>*/}
                {/*</p>*/}
            </div>
            <PasswordReset />
            <Manage2FA />
        </PreferencesContainer>
    );
};

export default Preferences;
