import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@a2d24-ui/theme';
import { observer } from '@a2d24/emr-state/mobx-react-lite';

const LoadingRoute = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/diagnostics/send`);
    }, []);

    const location = useLocation();

    if (location.pathname === '/') return <Container>Welcome to EMR</Container>;

    return <Container>Loading {location.pathname}</Container>;
};

export default observer(LoadingRoute);

const Container = styled('div', {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
});
