import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { styled } from '@a2d24-ui/theme';


const ReceptionApp = () => {

    return (
        <Container>
            <ScrollableContainer>
                <Routes>
                    <Route path='/' element={<Navigate to='/diagnostics' />} />
                </Routes>
            </ScrollableContainer>
        </Container>
    );
};

const ReceptionRoute = (
    <Route path='receptionist/*' element={<ReceptionApp />} key={'receptionist'} />
);

export default ReceptionRoute;


const Container = styled('div', {
    width: '100%',
    height: '100%',
    minHeight: 'min-content',
    position: 'relative',
    overflow: 'initial'
});

const ScrollableContainer = styled(Container, {
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
});
