// Auto-Generated by @a2d24/react-engine. Do not edit directly

import initEngine from '@a2d24/react-engine';
import renderRootComponent from '@a2d24/react-engine-dom';

// prettier-ignore
const engineConfig = {
    'modules': {
        'root-router': '@a2d24/root-router',
        'root-wrappers': '@a2d24/root-wrappers',
        'emr-template-module': '@a2d24/emr-template-module',
        'emr-state': '@a2d24/emr-state',
        'emr-core': '@a2d24/emr-core',
        'emr-login': '@a2d24/emr-login',
        'emr-patient': '@a2d24/emr-patient',
        'emr-reception': '@a2d24/emr-reception',
        'client-error-boundary': '@a2d24/client-error-boundary',
        'react-query-provider': '@a2d24/react-query-provider',
        'alert-provider': '@a2d24/alert-provider'
    },
    'template': '@a2d24/emr-template-module',
    'render': 'root-router.RootRouter',
    'moduleGenerators': {
        'root-router': () => require('@a2d24/root-router').default,
        'root-wrappers': () => require('@a2d24/root-wrappers').default,
        'emr-template-module': () => require('@a2d24/emr-template-module').default,
        'emr-state': () => require('@a2d24/emr-state').default,
        'emr-core': () => require('@a2d24/emr-core').default,
        'emr-login': () => require('@a2d24/emr-login').default,
        'emr-patient': () => require('@a2d24/emr-patient').default,
        'emr-reception': () => require('@a2d24/emr-reception').default,
        'client-error-boundary': () => require('@a2d24/client-error-boundary').default,
        'react-query-provider': () => require('@a2d24/react-query-provider').default,
        'alert-provider': () => require('@a2d24/alert-provider').default
    }
};

try {
    initEngine(engineConfig);
    renderRootComponent();
} catch (e) {
    console.error(e);
}
